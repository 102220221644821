<template>
    <div class="header header-bg-lg header-bg-shape border-bottom-1">
        <div class="container-fluid p-lg-0">
            <div class="row m-lg-0">
                <div class="col-lg-6 p-lg-0">
                    <div class="max-585 ms-auto">
                        <div class="header-content">
                            <h1>Bridging <font color="#EF3D1E">Academia</font> and <font color="#EF3D1E">Industry</font> for a Safer Tomorrow</h1>
                            <p>Welcome to CIPHERARMOR, a cybersecurity think-tank on a mission to redefine digital safety. With a unique fusion of industry expertise and academic insight,
                                we're pioneering the future of cybersecurity.
                            </p>
                            <div class="button-group button-group-animated">
                                <router-link to="/contact" class="btn main-btn">
                                    Get Started
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-image-shape">
            <img src="../../assets/images/homepage-one/header-shape.png" alt="shape">
        </div>
        <div class="header-animation-shapes animation-d-none">
            <div class="header-animation-shape"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>