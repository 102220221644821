<!--<template>
    <div class="process-section pt-100 pb-70 bg-off-white">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Our Process</small>
                <h2>Process How Machine Learning Will Help Your Business</h2>
            </div>
            <div class="process-content">
                <div class="process-content-line"></div>
                <div class="row justify-content-center">
                    <div class="col-sm-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-one.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Planning</h3>
                                <p>Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-two.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Design & Execution</h3>
                                <p>Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-three.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Report & Signout</h3>
                                <p>Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>-->

<script>
export default {
    name: 'Process'
}
</script>