<template>
    <footer class="bg-off-white footer">
        <div class="footer-shapes">
            <div class="footer-shape">
                <img src="../../assets/images/shape-1.png" alt="shape">
            </div>
            <div class="footer-shape footer-round-shape">
                <img src="../../assets/images/shape-2.png" alt="shape">
            </div>
        </div>
        <div class="footer-upper pt-100 pb-80 position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="footer-content-item">
                            <div class="footer-logo">
                                <router-link to="/">
                                    <img src="../../assets/images/logo.png" alt="logo">
                                </router-link>
                            </div>
                            <div class="footer-details">
                                <p>
                                    Welcome to CIPHERARMOR, a cybersecurity think-tank on a mission to redefine digital safety. With a unique fusion 
                                    of industry expertise and academic insight, we're pioneering the future of cybersecurity.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/contact">Contact Us</router-link></li>
                                <li><router-link to="/terms-of-service">Terms Of Service</router-link></li>
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Contact</h3>
                            </div>
                            <div class="footer-details footer-address">
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Email:</h4>
                                        <p><a href="mailto:info@cipherarmor.com">info@cipherarmor.com</a></p>
                                    </div>
                                </div>
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Address:</h4>
                                        <p>1150 NW 72ND AVE TOWER I, MIAMI, FL</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-lower bg-blue position-relative">
            <div class="container">
                <div class="footer-copyright-text footer-copyright-text-white">
                    <p>Copyright ©{{currentYear}} CIPHERARMOR LLC. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>