<template>
    <div class="trial-section pt-min-100 pb-100 bg-main page-bg page-bg-1">
        <div class="container">
            <div class="section-title section-title-lg section-title-white mb-0">
                <h2>Grant Funded Research</h2>
                <p>
                    Our research is often funded through grants, reflecting the significance and potential impact of our work.
                    We're proud to contribute to the broader cybersecurity field and to leverage our findings to deliver innovative,
                    practical solutions for businesses and individuals alike.
                </p>
                <!--<router-link to="/contact" class="btn main-btn main-btn-white main-btn-rounded-icon">
                    Try 14 Days Free Trial 
                    <span><i class="icofont-rounded-right"></i></span>
                </router-link> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FreeTrial'
}
</script>