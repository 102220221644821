<template>
    <div class="feature-section pt-min-100 pb-70">
        <div class="container">
            <div class="section-title section-title-lg">
                <h2>Any Sufficiently Advanced <br> Technology Is Indistinguishable From Magic</h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>Cybersecurity Consultation</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Our experts work closely with your team to understand your specific needs and provide tailored cybersecurity
                                    startegies. We help you anticipate and prepare for both current and emerging threats.
                                </p>
                            <!--    <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>Penetration Testing</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Our team of certified ethical hackers will perform thorough assessments of your infrastructure, identifying
                                    vulnerabilities and testing your defenses to ensure you're adequately protected.
                                </p>
                            <!--    <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-blue">
                                    <i class="flaticon-machine-learning"></i>
                                </div>
                                <span>Secure Software Development</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>We leverage our research findings to develop software with built-in security. Our team can work with yours to 
                                    create secure software solutions that suit your business needs.
                                </p>
                            <!--    <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-violet">
                                    <i class="flaticon-processor"></i>
                                </div>
                                <span>AI Technology</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>We invite universities and businesses alike to join us in groundbreaking AI cybersecurity research.
                                    Together, we can drive innovation and make the digital world safer.
                                </p>
                            <!--    <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>