<template>
    <div class="contact-form-section pb-100">
        <div class="container">
            <div class="contact-form-box">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="contact-map contact-form-item">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1796.3302050438303!2d-80.31524656365923!3d25.78177800556427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b9a4624fffff%3A0xbab0bafbdb5aaa2a!2s1150%20NW%2072nd%20Ave%20Tower%20I%20ste%20455%2C%20Miami%2C%20FL%2033126!5e0!3m2!1sen!2sus!4v1686782223611!5m2!1sen!2sus" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-form-item desk-pad-left-40">
                            <div class="section-title text-start section-title-left">
                                <h2>Let's Get In Touch!</h2>
                            </div>
                            <form class="contact-form"  action="https://formspree.io/f/xeqbjvdl" method="POST">
                                <div class="form-group mb-20">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Your Name*" required/>
                                </div>
                                <div class="form-group mb-20">
                                    <input type="text" name="email" id="email" class="form-control" placeholder="Your Email*" required/>
                                </div>
                                <div class="form-group mb-20">
                                    <input type="text" name="phone" id="phone" class="form-control" placeholder="Your Phone*" required/>
                                </div>
                                <div class="form-group mb-20">
                                    <input type="text" name="subject" id="subject" class="form-control" placeholder="Your Subject*" required/>
                                </div>
                                <div class="form-group mb-20">
                                    <textarea name="message" class="form-control" id="message" rows="3" placeholder="Your Question*" required></textarea>
                                </div>
                                <div class="input-checkbox mb-20">
                                    <input type="checkbox" id="contact1">
                                    <label for="contact1">
                                        Accept
                                        <router-link to="/terms-of-service">Terms of Service</router-link>
                                        and
                                        <router-link to="/privacy-policy">Privacy Policy</router-link>
                                    </label>
                                </div>
                                <div class="form-button">
                                    <button class="btn main-btn full-width" type="submit">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactForm'
}
</script>
