<template>
    <div class="about-section bg-off-white pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-image text-center">
                        <img src="../../assets/images/feature-shape/feature-shape-1.png" alt="shape">
                    </div>
                </div>
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-details">
                        <div class="section-title section-title-left text-start">
                            <small>About Us</small>
                            <h2>Research That Matters</h2>
                        </div>
                        <div class="about-content">
                            <p>We're not just a company; we're a collaborative community that brings together the sharpest minds from academia and
                                industry. Our partnerships with universities fuel our innovation engine, encouraging a vibrant exchange of ideas,
                                insights, and inspirations.
                            </p>
                            <ul>
                                <li>Advance Advisory Team</li>
                                <li>Be Part of the Change</li>
                                <li>Where Innovation Meets Collaboration</li>
                                <li>Grant-Funded Research</li>
                            </ul>
                            <p>Fuelled by grants, our research isn't just innovative - it's impactful. From harnessing Artificial Intelligence to couter 
                                cyber threats, to securing the Internet of Things and exploring quantum-resistant encryption, we're at the forefront of
                                critical areas in cybersecurity.
                            </p>
                            <!--<router-link to="/about-us" class="btn main-btn">
                                About Us
                            </router-link>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>